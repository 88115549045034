import React from "react"
import theme from "theme"
import { Theme, Text, Image, Box, Section } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"services"} />
      <Helmet>
        <title>Nasze usługi | Poznaj usługi w SparkleWorks Cleaning Co.</title>
        <meta
          name={"description"}
          content={"Od narożników po korytarze: Każda przestrzeń lśni"}
        />
        <meta
          property={"og:title"}
          content={"Nasze usługi | Poznaj usługi w SparkleWorks Cleaning Co."}
        />
        <meta
          property={"og:description"}
          content={"Od narożników po korytarze: Każda przestrzeń lśni"}
        />
        <meta
          property={"og:image"}
          content={"https://mixorel.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://mixorel.com/img/594856.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://mixorel.com/img/594856.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://mixorel.com/img/594856.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://mixorel.com/img/594856.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://mixorel.com/img/594856.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://mixorel.com/img/594856.png"}
        />
      </Helmet>
      <Components.Header />
      <Section
        padding="80px 0 80px 0"
        sm-padding="30px 0 30px 0"
        md-padding="40px 0 40px 0"
        quarkly-title="About-13"
      >
        <Override
          slot="SectionContent"
          flex-direction="row"
          flex-wrap="wrap"
          sm-min-width="280px"
        />
        <Text
          margin="0px 0px 70px 0px"
          font="normal 600 46px/1.2 --fontFamily-sans"
          md-margin="0px 0px 50px 0px"
          text-align="center"
          width="100%"
        >
          Nasze kompleksowe rozwiązania w zakresie sprzątania
        </Text>
        <Box
          display="grid"
          grid-gap="32px"
          sm-grid-gap="16px"
          align-items="center"
          width="50%"
          lg-width="100%"
        >
          <Image
            src="https://mixorel.com/img/3.jpg"
            object-fit="cover"
            width="100%"
            height="100%"
            max-height="460px"
          />
        </Box>
        <Box
          display="flex"
          width="50%"
          flex-direction="column"
          justify-content="center"
          align-items="flex-start"
          lg-align-items="center"
          padding="42px 42px 42px 42px"
          lg-width="100%"
          md-padding="24px 24px 24px 24px"
          md-margin="24px 0px 0px 0px"
          sm-align-items="flex-start"
          sm-padding="24px 24px 24px 0px"
        >
          <Text
            margin="0px 0px 40px 0px"
            color="--greyD2"
            font="--base"
            lg-text-align="center"
            sm-text-align="left"
          >
            W SparkleWorks oferujemy szereg specjalistycznych usług sprzątania
            zaprojektowanych w celu zaspokojenia unikalnych potrzeb Twojego
            biura. Poniżej znajduje się szczegółowy przegląd naszych kategorii
            usług i ich zakresu:
          </Text>
        </Box>
      </Section>
      <Section padding="90px 0 100px 0" quarkly-title="Reviews-3">
        <Box
          min-width="100px"
          display="grid"
          grid-template-columns="repeat(3, 1fr)"
          grid-gap="36px 30px"
          justify-content="center"
          lg-grid-template-rows="auto"
          sm-grid-template-columns="1fr"
          sm-grid-gap="30px"
        >
          <Box
            min-width="100px"
            padding="30px 30px 30px 30px"
            background="#f3f3f7"
            border-radius="8px"
            box-shadow="--m"
            margin="0px 0 0px 0px"
            display="flex"
            flex-direction="column"
            justify-content="space-between"
            lg-margin="0px 0 0 0px"
            sm-padding="20px 15px 20px 15px"
            md-padding="20px 20px 20px 20px"
          >
            <Box min-width="100px">
              <Text
                margin="0px 0px 7px 0px"
                font="normal 600 20px/1.5 --fontFamily-sans"
              >
                Regularne usługi sprzątania
              </Text>
              <Text
                margin="0px 0px 28px 0px"
                font="normal 300 17px/1.5 --fontFamily-sansHelvetica"
                color="--darkL1"
                md-margin="0px 0px 18px 0px"
              >
                Codzienne sprzątanie biur: Utrzymuj nieskazitelne środowisko
                pracy dzięki naszym codziennym procedurom sprzątania, które
                obejmują usuwanie śmieci, odkurzanie i ścieranie kurzu.
                <br />
                <br />
                Wywóz śmieci i recykling: Regularne usługi utylizacji i
                recyklingu w celu utrzymania porządku.
                <br />
                <br />
                Odkurzanie dywanów i podłóg: Stosowanie wysokowydajnych filtrów
                do wychwytywania kurzu i alergenów.
                <br />
                <br />
                Odkurzanie miejsc pracy: Dokładne odkurzanie w celu utrzymania
                czystego i wolnego od alergenów środowiska.
              </Text>
            </Box>
          </Box>
          <Box
            min-width="100px"
            padding="30px 30px 30px 30px"
            background="#f3f3f7"
            border-radius="8px"
            box-shadow="--m"
            margin="0px 0 0px 0px"
            display="flex"
            flex-direction="column"
            justify-content="space-between"
            lg-margin="0px 0 0 0px"
            sm-padding="20px 15px 20px 15px"
            md-padding="20px 20px 20px 20px"
          >
            <Box min-width="100px">
              <Text
                margin="0px 0px 7px 0px"
                font="normal 600 20px/1.5 --fontFamily-sans"
              >
                Usługi głębokiego czyszczenia
              </Text>
              <Text
                margin="0px 0px 28px 0px"
                font="normal 300 17px/1.5 --fontFamily-sansHelvetica"
                color="--darkL1"
                md-margin="0px 0px 18px 0px"
              >
                Comiesięczne dogłębne czyszczenie: Bardziej intensywna opcja
                czyszczenia, która obejmuje obszary rzadziej zajmowane w
                codziennej rutynie.
                <br />
                <br />
                Czyszczenie tapicerki: Dogłębne czyszczenie tkanin i mebli w
                celu przywrócenia ich wyglądu.
                <br />
                <br />
                Mycie okien: Krystalicznie czyste mycie okien zapewniające
                przejrzystość bez smug.
                <br />
                <br />
                Woskowanie podłóg: Woskowanie podłóg na wysoki połysk dla
                nieskazitelnego wykończenia.
              </Text>
            </Box>
          </Box>
          <Box
            min-width="100px"
            padding="30px 30px 30px 30px"
            background="#f3f3f7"
            border-radius="8px"
            box-shadow="--m"
            margin="0px 0 0px 0px"
            display="flex"
            flex-direction="column"
            justify-content="space-between"
            lg-margin="0px 0 0 0px"
            sm-padding="20px 15px 20px 15px"
            md-padding="20px 20px 20px 20px"
          >
            <Box min-width="100px">
              <Text
                margin="0px 0px 7px 0px"
                font="normal 600 20px/1.5 --fontFamily-sans"
              >
                Specjalistyczne usługi czyszczenia
              </Text>
              <Text
                margin="0px 0px 28px 0px"
                font="normal 300 17px/1.5 --fontFamily-sansHelvetica"
                color="--darkL1"
                md-margin="0px 0px 18px 0px"
              >
                Czyszczenie techniki i sprzętu: Specjalna pielęgnacja wrażliwych
                obszarów, takich jak serwerownie i stanowiska komputerowe.
                <br />
                <br />
                Bezpieczne dla elektroniki produkty czyszczące: Specjalnie
                zaprojektowane środki czyszczące do ochrony i czyszczenia
                technologii.
                <br />
                <br />
                Środki antystatyczne: Ograniczają gromadzenie się kurzu i
                chronią urządzenia elektroniczne.
              </Text>
            </Box>
          </Box>
          <Box
            min-width="100px"
            padding="30px 30px 30px 30px"
            background="#f3f3f7"
            border-radius="8px"
            box-shadow="--m"
            margin="0px 0 0px 0px"
            display="flex"
            flex-direction="column"
            justify-content="space-between"
            lg-margin="0px 0 0 0px"
            sm-padding="20px 15px 20px 15px"
            md-padding="20px 20px 20px 20px"
          >
            <Box min-width="100px">
              <Text
                margin="0px 0px 7px 0px"
                font="normal 600 20px/1.5 --fontFamily-sans"
              >
                Ekologiczne czyszczenie
              </Text>
              <Text
                margin="0px 0px 28px 0px"
                font="normal 300 17px/1.5 --fontFamily-sansHelvetica"
                color="--darkL1"
                md-margin="0px 0px 18px 0px"
              >
                Ekologiczne rozwiązania czyszczące: Stosowanie produktów i metod
                przyjaznych dla środowiska.
                <br />
                <br />
                Nietoksyczne środki czyszczące: Bezpieczne dla zespołu i
                środowiska.
                <br />
                <br />
                Materiały z recyklingu: Wszystkie materiały czyszczące są
                przyjazne dla środowiska i zrównoważone.
              </Text>
            </Box>
          </Box>
          <Box
            min-width="100px"
            padding="30px 30px 30px 30px"
            background="#f3f3f7"
            border-radius="8px"
            box-shadow="--m"
            margin="0px 0 0px 0px"
            display="flex"
            flex-direction="column"
            justify-content="space-between"
            lg-margin="0px 0 0 0px"
            sm-padding="20px 15px 20px 15px"
            md-padding="20px 20px 20px 20px"
          >
            <Box min-width="100px">
              <Text
                margin="0px 0px 7px 0px"
                font="normal 600 20px/1.5 --fontFamily-sans"
              >
                Indywidualne plany czyszczenia
              </Text>
              <Text
                margin="0px 0px 28px 0px"
                font="normal 300 17px/1.5 --fontFamily-sansHelvetica"
                color="--darkL1"
                md-margin="0px 0px 18px 0px"
              >
                Dostosowane do Twoich potrzeb: Tworzymy harmonogram sprzątania i
                listę usług w oparciu o konkretne wymagania.
                <br />
                <br />
                Elastyczny harmonogram: Wybierz, kiedy i jak często potrzebujesz
                naszych usług.
                <br />
                <br />
                Niestandardowy wybór usług: Mieszaj i dopasowuj usługi, aby
                stworzyć idealny plan dla swojego biura.
              </Text>
            </Box>
          </Box>
          <Box
            min-width="100px"
            padding="30px 30px 30px 30px"
            background="#f3f3f7"
            border-radius="8px"
            box-shadow="--m"
            margin="0px 0 0px 0px"
            display="flex"
            flex-direction="column"
            justify-content="space-between"
            lg-margin="0px 0 0 0px"
            sm-padding="20px 15px 20px 15px"
            md-padding="20px 20px 20px 20px"
          >
            <Box min-width="100px">
              <Text
                margin="0px 0px 7px 0px"
                font="normal 600 20px/1.5 --fontFamily-sans"
              >
                Usługi sprzątania awaryjnego
              </Text>
              <Text
                margin="0px 0px 28px 0px"
                font="normal 300 17px/1.5 --fontFamily-sansHelvetica"
                color="--darkL1"
                md-margin="0px 0px 18px 0px"
              >
                Sprzątanie w nagłych wypadkach: Szybkie i skuteczne rozwiązania
                w nieoczekiwanych sytuacjach.
                <br />
                <br />
                Natychmiastowe wezwania serwisowe: Szybkie rozmieszczenie
                naszych zespołów w celu zaspokojenia wszelkich pilnych potrzeb w
                zakresie sprzątania.
                <br />
                <br />
                Sprzątanie po imprezach: Idealne rozwiązanie po spotkaniach,
                imprezach i innych dużych wydarzeniach.
              </Text>
            </Box>
          </Box>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
